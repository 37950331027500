<template>
    <div>
        <button class="btn th-custom-color mr-2" @click="showModal = true;">Nuevo</button>
        <b-modal
        v-model="showModal"
        id="modalPuntoVenta"
        title="Nuevo: Punto de Venta" size="md" hide-footer
        >
            <div class="modal-body">
                <div>
                    <div class="form-row">
                        <div class="col-12">
                            <div class="form-group row">
                                <label for="nombre" class="col-sm-4 col-form-label text-right">
                                    Nombre:
                                </label>
                                <div class="col-sm-8 pl-0">
                                    <input type="text" class="form-control" v-model="puntonVenta.nombre" required>
                                    <small id="nombreHelp" class="text-danger" v-show="showErrorNombre">
                                        {{ errorNombre }}
                                    </small>   
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group row">
                                <label for="nombre" class="col-sm-4 col-form-label text-right">
                                    Descripcion:
                                </label>
                                <div class="col-sm-8 pl-0">
                                    <input type="text" class="form-control" v-model="puntonVenta.descripcion" required>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group row">
                                <label for="sucursal" class="col-sm-4 col-form-label text-right">
                                    Sucursal:
                                </label>
                                <div class="col-sm-8 pl-0">
                                    <multiselect
                                        id="sucursal"
                                        track-by="nombre_comercial"
                                        label="nombre_comercial"
                                        placeholder="Seleccione una Sucursal"
                                        v-model="puntonVenta.siat_sucursal"
                                        :show-labels="false"
                                        :options="listaTipoSucursales"
                                        :searchable="true"
                                        >
                                    </multiselect>                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group row">
                                <label for="tipo_punto_venta" class="col-sm-4 col-form-label text-right">
                                    Tipo Punto de Venta:
                                </label>
                                <div class="col-sm-8 pl-0">
                                    <multiselect
                                        id="sucursal"
                                        track-by="descripcion"
                                        label="descripcion"
                                        placeholder="Seleccione un Punto de Venta"
                                        v-model="puntonVenta.tipo_punto_venta"
                                        :show-labels="false"
                                        :options="listaTipoPuntoVenta"
                                        :searchable="true"
                                        >
                                    </multiselect>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button class="btn btn-secondary" @click="showModal = false;">Cancelar</button>
                    <button class="btn th-custom-color" @click="actionPuntoVenta"  style="margin-left:11px;">Guardar</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>

import { call, get, sync } from 'vuex-pathify';
import Multiselect from 'vue-multiselect';

export default {
    name: "TheModalPuntoVenta",
    mounted(){
        // this.getPuntosVentaCreate()
    },
    methods:{
        ...call('sincronizacion/puntosVenta', [
            'getPuntosVenta',
            'getPuntosVentaCreate',
            'storePuntoVenta',
        ]),
        actionPuntoVenta(){
            this.storePuntoVenta(this.puntonVenta)
        }
    },
    computed: {
        ...get('sincronizacion/puntosVenta', [
            'listaTipoPuntoVenta',
            'listaTipoSucursales',
        ]),
        showModal: sync('sincronizacion/puntosVenta/showModal'),
        ...sync('sincronizacion/puntosVenta', [
            'showErrorNombre',
            'showErrorSucursal',
            'showErrorTipoPuntoVenta',
            'errorNombre',
            'errorSucursal',
            'errorTipoPuntoVenta',
        ])
    },
    watch: {
        showModal() {
            if(this.showModal) {
                this.getPuntosVentaCreate();
            }
        }
    },
    components: {
        Multiselect
    },
    data() {
        return {
            puntonVenta : {
                nombre: "",
                descripcion: "",
                tipo_punto_venta: null,
                siat_sucursal: null,
            }
        }
    }
}
</script>