<template>
    <div class="row mb-1">
        <div class="col-sm-12 py-3 px-2">
            <div class="row mx-0">
                <div class="col-sm-3 p-0">
                    <div class="tab tabla-scroll-vertical px-0 mx-0">
                        <!-- <button class="tablinks active" @click="openCity($event, 'codigo'); obtenerCuis()">Codigos</button> -->
                        <button class="tablinks" @click="openCity($event, 'leyenda_facturas'); listarLeyendas(); setTitle('Lista Leyendas');">Listas Leyendas</button>
                        <!-- ingresar modal -->
                        <button class="tablinks" @click="openCity($event, 'lista_mensajes'); listarMensajes(); setTitle('Lista Mensajes');">Lista Mensajes</button>
                        <!-- fin modal -->
                        <button class="tablinks" @click="openCity($event, 'produccion_servicios'); listarProductosServicios(); setTitle('Lista de Productos/Servicios');">Lista de Productos/Servicios</button>
                        <button class="tablinks" @click="openCity($event, 'evento_significativo'); listarEventosSignificativos(); setTitle('Lista Eventos Significativos');">Lista Eventos Significativos</button>
                        <button class="tablinks" id="defaultOpen" @click="openCity($event, 'unidad_medida'); listarUnidadesMedidas(); setTitle('Unidades de Medida');">
                            Unidades de Medida
                        </button>
                         <!-- ingresar model  -->
                          <button class="tablinks" @click="openCity($event, 'lista_paises'); listarPaises(); setTitle('Lista Paises');">Lista Paises</button>
                        <button class="tablinks" @click="openCity($event, 'tipo_documento_identidad'); listarTipoDocIdentidad(); setTitle('Lista Tipos de Doc. de Identidad');">
                           Lista Tipos de Doc. de Identidad
                        </button>
                        <button class="tablinks" @click="openCity($event, 'tipo_documento_sector'); listarDocumentoSector(); setTitle('Lista Documentos Sector');">
                            Lista Documentos Sector
                        </button>
                        <button class="tablinks" @click="openCity($event, 'tipo_emision'); listarTipoEmision(); setTitle('Tipos de Emision');">
                            Tipos de Emision
                        </button>
                        <button class="tablinks" @click="openCity($event, 'actividad_sector'); listarActividadSector(); setTitle('Lista Actividades');">Lista Actividades</button>
                        <button class="tablinks" @click="openCity($event, 'tipo_moneda'); listarTipoMoneda(); setTitle('Tipos de Moneda');">
                            Tipos de Moneda
                        </button>
                        <button class="tablinks" @click="openCity($event, 'motivo_anulacion'); listarMotivoAnulacion(); setTitle('Motivo Anulaciòn');">
                            Motivo Anulación
                        </button>
                        <!-- ingresar modal -->
                         <button class="tablinks" @click="openCity($event, 'tipo_habitacion'); listarHabitaciones(); setTitle('Tipos de Habitaciones');">
                            Tipos de Habitaciones
                        </button>
                         <button class="tablinks" @click="openCity($event, 'actividad_economica'); listarActividadDocumentoSector(); setTitle('Actividad Economica');">Actividad Economica</button>
                          <button class="tablinks" @click="openCity($event, 'tipo_facturas'); listarTipoFactura(); setTitle('Tipo de Factura');">
                            Tipo de Factura
                        </button>
                        <button class="tablinks" @click="openCity($event, 'tipo_metodo_pago'); listarMetodoPago(); setTitle('Tipo Metodo de Pago');">
                            Tipo Metodo de Pago
                        </button>
                        <!--  ingresar modal  -->
                         <button class="tablinks" @click="openCity($event, 'hora_impuestos'); listarFechaHora(); setTitle('Hora Impuesto');">
                            Hora Impuesto
                        </button>
                        <button class="tablinks" @click="openCity($event, 'tipo_punto_venta'); listarPuntosVenta(); setTitle('Tipos de Punto de Ventas');">
                            Tipos de Punto de Ventas
                        </button>
                    </div>
                    
                </div>
                <div class="col-sm-9 mx-0" style="width:100% !important">
                    <div class="row py-2">
                        <div class="col-sm-6">
                            <h3>{{titleModal}}</h3>
                            <label for="" style="padding-top:8px; white-space: nowrap;">
                                Esta informacion es proporcionada por impuesto al sincronizar
                            </label>
                        </div>
                        <div class="col-sm-6 text-right">
                            <button 
                                class="btn th-custom-color" 
                                @click="syncSiat"
                                :disabled="loading"
                                >
                                Sincronizar Todos
                            </button>
                        </div>
                    </div>
                    <hr>
                    <div id="unidad_medida" class="tabcontent">
                        <TabUnidadMedida/>
                    </div>
                    <div id="actividad_economica" class="tabcontent" style="display:none">
                        <TabActEconomica/>
                    </div>
                    <div id="actividad_sector" class="tabcontent" style="display:none">
                        <TabActividadSector/>
                    </div>
                    <div id="leyenda_facturas" class="tabcontent" style="display:none">
                        <TabLeyendas/>
                    </div>
                    <div id="produccion_servicios" class="tabcontent" style="display:none">
                        <TabProductoServicio/>
                    </div>
                    <div id="evento_significativo" class="tabcontent" style="display:none">
                        <TabEventos/>
                    </div>
                    <div id="motivo_anulacion" class="tabcontent" style="display:none">
                        <TabAnulacion/>
                    </div>
                    <div id="tipo_documento_identidad" class="tabcontent" style="display:none">
                        <TabTipoDocIdentidad/>
                    </div>
                    <div id="tipo_documento_sector" class="tabcontent" style="display:none">
                        <TabDocumentoSector/>
                    </div>
                    <div id="tipo_emision" class="tabcontent" style="display:none">
                        <TabTipoEmision/>
                    </div>
                    <div id="tipo_metodo_pago" class="tabcontent" style="display:none">
                        <TabMedioPago/>
                    </div>
                    <div id="tipo_moneda" class="tabcontent" style="display:none">
                        <TabTipoMoneda/>
                    </div>
                    <div id="tipo_punto_venta" class="tabcontent" style="display:none">
                        <TabTipoPuntoVenta/>
                    </div>
                    <div id="tipo_facturas" class="tabcontent" style="display:none">
                        <TabTipoFactura/>
                    </div>
                    <div id="hora_impuestos" class="tabcontent" style="display:none">
                        <TheViewImpuestos/>
                    </div>
                    <div id="tipo_habitacion" class="tabcontent" style="display:none">
                        <TheViewHabitaciones/>
                    </div>
                    <div id="lista_mensajes" class="tabcontent" style="display:none">
                        <TheViewMensajes/>
                    </div>
                    <div id="lista_paises" class="tabcontent" style="display:none">
                        <TheViewPaises/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { get, sync, call } from 'vuex-pathify';
import TabEventos from "./TabEventosSignificativo.vue";
import TabLeyendas from "./TabLeyenda.vue";
import TabActEconomica from "./TabActEconomicas.vue";
import TabAnulacion from "./tabAnulacion.vue";

import TabTipoFactura from "./TabTipoFactura.vue";
import TabTipoMoneda from "./TabTipoMoneda.vue";
import TabMedioPago from "./TabTipoMetodoPago.vue";
import TabTipoEmision from "./TabTipoEmision.vue";
import TabTipoPuntoVenta from "./TabTipoPuntoVenta.vue";
import TabDocumentoSector from "./TabDocumentoSector.vue";
import TabTipoDocIdentidad from "./TabTipoDocIdentidad.vue";
import TabProductoServicio from "./TabProductoServicio.vue";
import TabActividadSector from "./TabActividadSector.vue";
// import TabCodigo from "./TabCodigo.vue";
import TabUnidadMedida from "./TabUnidadMedidas.vue";
import TheViewImpuestos from "./TheViewImpuestos.vue";
import TheViewHabitaciones from "./TheViewHabitaciones.vue";
import TheViewMensajes from "./TheViewMensajes.vue";
import TheViewPaises from "./TheViewPaises.vue";

export default {
    name:'sincronizar',
    components: {
    TabActEconomica,
    TabLeyendas,
    TabEventos,
    TabAnulacion,
    TabTipoFactura,
    TabTipoMoneda,
    TabMedioPago,
    TabTipoEmision,
    TabTipoPuntoVenta,
    TabDocumentoSector,
    TabTipoDocIdentidad,
    TabProductoServicio,
    TabActividadSector,
    // TabCodigo,
    TabUnidadMedida,
    TheViewImpuestos,
    TheViewHabitaciones,
    TheViewMensajes,
    TheViewPaises
},
    data() {
        return {
            availableTabs : {
                'leyenda_facturas' : true,
                'lista_mensajes' : false,
                'produccion_servicios' : false,
                'evento_significativo' : false,
                'unidad_medida' : false,
                'motivo_anulacion' : false,
                'lista_paises' : false,
                'tipo_documento_identidad' : false,
                'tipo_documento_sector' : false,
                'tipo_emision' : false,
                'actividad_sector' : false,
                'tipo_moneda' : false,
                'tipo_habitacion' : false,
                'actividad_economica' : false,
                'tipo_facturas' : false,
                'tipo_metodo_pago' : false,
                'hora_impuestos' : false,
                'tipo_punto_venta' : false,
            },
            titleModal: null,
        };
    },
    mounted(){
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        
        document.getElementById('leyenda_facturas').style.display = "block";
        tablinks[0].className += " active";
        this.listarLeyendas();
        this.titleModal = 'Lista Leyendas';
    },
    computed: {
        ...get('sincronizacion/sucursal', [
            'tablaSucursals',
        ]),
        loading: sync('sincronizacion/parametroSiat/loading'),
    },
    methods: {
        openCity(evt, cityName, otherCity = undefined) {
            let i, tabcontent, tablinks;
            tabcontent = document.getElementsByClassName("tabcontent");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }
            tablinks = document.getElementsByClassName("tablinks");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            document.getElementById(cityName).style.display = "block";
            if(otherCity != undefined )
                this.availableTabs = this.availableTabs.map((elem, index) => index == otherCity)
            evt.currentTarget.className += " active";
        },
        setTitle(title) {
            this.titleModal = title;
        },
        ...call('sincronizacion/listarLeyendas', [
            'listarLeyendas'
        ]),
        ...call('sincronizacion/listarMensajes', [
            'listarMensajes'
        ]),
        ...call('sincronizacion/listarProductosServicios', [
            'listarProductosServicios'
        ]),
        ...call('sincronizacion/listarEventosSignificativos', [
            'listarEventosSignificativos'
        ]),
        ...call('sincronizacion/listarUnidadesMedidas', [
            'listarUnidadesMedidas'
        ]),
        ...call('sincronizacion/listarMotivoAnulacion', [
            'listarMotivoAnulacion'
        ]),
        ...call('sincronizacion/listarPaises', [
            'listarPaises'
        ]),
        ...call('sincronizacion/listarTipoDocIdentidad', [
            'listarTipoDocIdentidad'
        ]),
        ...call('sincronizacion/listarDocumentoSector', [
            'listarDocumentoSector'
        ]),
        ...call('sincronizacion/listarTipoEmision', [
            'listarTipoEmision'
        ]),
        ...call('sincronizacion/listarActividadSector', [
            'listarActividadSector'
        ]),
        ...call('sincronizacion/listarTipoMoneda', [
            'listarTipoMoneda'
        ]),
        ...call('sincronizacion/listarHabitaciones', [
            'listarHabitaciones'
        ]),
        ...call('sincronizacion/listarTipoFactura', [
            'listarTipoFactura'
        ]),
        ...call('sincronizacion/listarMetodoPago', [
            'listarMetodoPago'
        ]),
        ...call('sincronizacion/listarFechaHora', [
            'listarFechaHora'
        ]),
        ...call('sincronizacion/listarPuntosVenta', [
            'listarPuntosVenta'
        ]),
        ...call('sincronizacion/listarActividadDocumentoSector', [
            'listarActividadDocumentoSector'
        ]),
        ...call('sincronizacion/obtieneCuis', [
            'obtenerCuis'
        ]),
        ...call('sincronizacion/parametroSiat', [
            'syncSiat'
        ]),
    },
}
</script>
<style scoped>
    .tab {
    float: left;
    width: 30%;
    height: auto;
    }
    .tab button {
    display: block;
    background-color: inherit;
    color: black;
    padding: 15px 10px;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    cursor: pointer;
    transition: 0.3s;
    font-size: 13px;
    }
    .tab button:hover {
    background-color: #00bd7e;
    }
    .tab button.active {
    background-color: #00bd7e;
    }
    .tabcontent {
    float: left;
    padding: 0px 12px;
    width: 100%;
    border-left: none;
    height: auto;
    }
    .tabla-scroll-vertical {
        overflow-y: scroll;
        height: 600px;
        width: 100% !important;
    }
</style>