<template>
    <div>
        <div class="row mb-2">
            <div class="col-sm-12">
                <div class="row mb-2">
                    <div class="col-sm-12 py-3 px-2">
                        <div class="row">
                            <div class="col-sm-2">
                                <div class="row mb-2">
                                    <div class="col-sm-7">
                                        <label for="" 
                                        class="col-sm-4 col-form-label text-right"
                                        style="padding-top:8px;  white-space: nowrap;">
                                            Eventos/Contigencia
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-10 form-group text-right d-flex align-items-center justify-content-end">
                                <div class="pl-2">
                                    <input type="text"
                                    class="form-control"
                                    v-model="filter"
                                    @keyup="filtrarBusqueda"
                                    autocomplete="off"
                                    placeholder="Buscar"
                                    >
                                </div>
                                <div class="pl-2">
                                    <button class="btn btn-secondary mr-2" @click="openModalConsulta">Consultar</button>
                                </div>
                            </div>
                            <div style="display: block; width: 100%" class="px-4 pt-4">
                                <b-table
                                    class="table table-striped table-bordered table-hover"
                                    :fields="fields"
                                    :busy="loading"
                                    :items="listaEventos"
                                >
                                    <template #cell(pdf)>
                                        <i class="nav-icon i-File-Download font-weight-bold text-info"
                                        style="cursor: pointer;"
                                        title="pdf"></i>
                                    </template>
                                    <template #cell(cufd)="data">
                                        <span class="long-text">{{ data.item.cufd }}</span>
                                    </template>                                    
                                    <template #cell(estado)="data" >
                                        <button class="btn btn-sm btn-block" :class="data.item.estado == 'Abierto' ? 'btn-warning' : data.item.validacion_descripcion == 'OBSERVADA' ? 'btn-danger' : 'th-custom-color'" @click="[openModal(), detalles=data.item]">Ver Detalle</button>
                                    </template>                                    
                                </b-table>
                                <div class="row">
                                    <div class="col-sm-8"></div>
                                    <div class="col-sm-4">
                                        <b-pagination
                                            v-model="page"
                                            :total-rows="total"
                                            :per-page="porPagina"
                                            @input="getIndex"
                                            class="my-0 float-right"
                                        ></b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TheModalEventoContigencia :detalles="detalles"/>
            <TheModalEventoContigenciaConsulta />
        </div>
    </div>
</template>

<script>

import {  sync, call } from 'vuex-pathify';
import TheModalEventoContigencia from './TheModalEventoContigenciaDetalles.vue';
import TheModalEventoContigenciaConsulta from './TheModalEventoContigenciaConsulta.vue';

export default {
    name: 'TheViewEventoContigencia',
    data() {
        return {
            detalles: {}
        };
    },
    created() {
        this.getCreate();
        this.getIndex();
    },
    methods: {
        openModal() {
            this.$bvModal.show('modalEventoContigencia');
        },
        openModalConsulta() {
            this.$bvModal.show('modalEventoContigenciaConsulta');
        },
        ...call('sincronizacion/evento', [
            'getIndex',
            'getCreate'
        ]),
        filtrarBusqueda() {
            const DELAY = 400;
            clearTimeout(this.delayTimeout);
            this.delayTimeout = setTimeout(this.getIndex, DELAY);
        },
    },
    computed: {
        ...sync('sincronizacion/evento', [
            'fields',
            'loading',
            'listaEventos',
            'estado',
            'porPagina',
            'paginaOpciones',
            'filter',
            'delayTimeout',
            'total',
            'page',            
        ]),
    },
    components: {
        TheModalEventoContigencia,
        TheModalEventoContigenciaConsulta,
    },
    watch: {
        
    }
}
</script>

<style scoped>
.long-text {
    word-break:break-all !important;
}
</style>