<template>
    <div>
        <div class="row mb-3">
            <div class="col-sm-12 py-3 px-2">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="row mb-2">
                            <div class="col-sm-7">
                                <label for="" 
                                class="col-sm-4 col-form-label text-right"
                                style="padding-top:8px;  white-space: nowrap;">
                                    Esta informacion es propio de la empresa.
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 text-right">
                        <div class="btn-group pr-4">
                            <theModalPuntoVenta/>
                            <button class="btn btn-secondary" @click="syncPuntoVenta" :disabled="loadingSync">Sincronizar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-sm-12">
                <div class="row mb-2">
                    <div class="col-sm-12 table-responsive">
                        <b-table
                            class="table table-striped table-bordered table-hover"
                            :fields="fields"
                            :busy="loading"
                            :items="tablaPuntoVenta"
                        >
                            <template #cell(acciones)="data">
                                <i class="nav-icon i-Close-Window font-weight-bold text-danger pointer"
                                    style="cursor: pointer" title="Eliminar" @click="destroyPuntoVenta(data.item.id)"></i>
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import theModalPuntoVenta from "./theModalPuntoVenta.vue";
import { call, get, sync } from 'vuex-pathify';

export default {
    name: 'punto_venta',
    mounted(){
        this.getPuntosVenta()
    },
    methods:{
        ...call('sincronizacion/puntosVenta', [
            'getPuntosVenta',
            'syncPuntosVenta',
            'destroyPuntoVenta',
        ]),
        async syncPuntoVenta(){
            await this.syncPuntosVenta()
            this.getPuntosVenta()
        },
    },
    computed: {
        ...get('sincronizacion/puntosVenta', [
            'tablaPuntoVenta',
        ]),
        loading: sync('sincronizacion/puntosVenta/loading'),
        loadingSync: sync('sincronizacion/puntosVenta/loadingSync'),
    },
    data() {
        return {
            fields: [
                {
                    key: 'numero', label: 'N°', class: 'text-center', thStyle: { width: '3%' },
                },
                {
                    key: 'cod_punto_venta', label: 'Nro. PV.', class: 'text-center', thStyle: { width: '3%' },
                },
                {
                    key: 'nombre_punto_venta', label: 'Nombre', class: 'text-center', thStyle: { width: '20%' },
                },
                {
                    key: 'siat_sucursal_name', label: 'Sucursal', class: 'text-center', thStyle: { width: '13%' },
                },
                {
                    key: 'tipo_punto_venta', label: 'Tipo Punto de Venta', class: 'text-center', thStyle: { width: '15%' },
                },
                {
                    key: 'user_nombre', label: 'Usuarios', class: 'text-center', thStyle: { width: '8%' },
                },
                {
                    key: 'acciones', label: 'Acciones', class: 'text-center', thStyle: { width: '5%' }
                }
            ],
        };
    },
    components: {
        theModalPuntoVenta,
    },
}
</script>

<style scoped>
.btn-orange{
    color: white;
    background-color: orange;
}
</style>