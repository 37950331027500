<template>
    <div>
        <div class="row mb-2">
            <div class="col-sm-12">
                <div class="row mb-2">
                    <div class="col-sm-12 py-3 px-2">
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="row mb-2">
                                    <div class="col-sm-7">
                                        <label for="" 
                                        class="col-sm-4 col-form-label text-right"
                                        style="padding-top:8px;  white-space: nowrap;">
                                            Generar CUIS
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-8 btn-group justify-content-end pr-5">
                                <div class="pr-3">
                                    <multiselect
                                        id="sucursal"
                                        track-by="nombre_comercial"
                                        label="nombre_comercial"
                                        placeholder="Seleccione una Sucursal"
                                        v-model="cuiModel.siat_sucursal_id"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        :options="listaSucursales"
                                        :searchable="true"
                                        @select="setFilterPuntoVenta"
                                        >
                                    </multiselect>
                                </div>
                                <div class="pr-3">
                                    <multiselect
                                        id="punto_venta"
                                        track-by="nombre_punto_venta"
                                        label="nombre_punto_venta"
                                        placeholder="Seleccione un Punto de Venta"
                                        v-model="cuiModel.siat_punto_venta_id"
                                        :show-labels="false"
                                        :options="filterPuntoVenta"
                                        :searchable="true">
                                    </multiselect>
                                </div>
                                <div>
                                    <button class="btn th-custom-color" @click="handleStoreCufd" :disabled="loading">Registrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-sm-12 table-responsive">
                        <b-table
                            class="table table-striped table-bordered table-hover"
                            :fields="fields"
                            :busy="loading"
                            :items="tablaCuis"
                        >
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { call, get, sync } from 'vuex-pathify';
import Multiselect from 'vue-multiselect';

export default {
    name: 'TheViewCuis',
    components: {
        Multiselect,
    },
    mounted(){
        this.getCUIS()
        this.getCreate()
    },
    methods:{
        ...call('sincronizacion/cui', [
            'getCUIS',
            'getCreate',
            'storeCui'
        ]),
        async handleStoreCufd(){
            await this.storeCui(this.cuiModel)
            await this.getCUIS()
        },
        setFilterPuntoVenta(data) {
            this.filterPuntoVenta = this.listaPuntoVenta.filter(e => e.siat_sucursal_id == data.id);
        }
    },
    computed: {
        ...get('sincronizacion/cui', [
            'tablaCuis',
            'listaPuntoVenta',
            'listaSucursales',
        ]),
        ...sync('sincronizacion/cui', [
            'loading',
            'showErrorSucursal',
            'showErrorPuntoVenta',
            'errorSucursal',
            'errorPuntoVenta',
        ]),
    },
    data() {
        return {
            cuiModel: {
                siat_punto_venta_id: '',
                siat_sucursal_id: '',
            },
            filterPuntoVenta: [],
            fields: [
                {
                    key: 'numero', label: 'N°', class: 'text-center', thStyle: { width: '3%' },
                },
                {
                    key: 'codigo', label: 'Cuis', class: 'text-center', thStyle: { width: '15%' },
                },
                {
                    key: 'nombre_sucursal', label: 'Sucursal', class: 'text-center', thStyle: { width: '10%' },
                },
                {
                    key: 'nombre_punto_venta', label: 'Punto de Venta', class: 'text-center', thStyle: { width: '16%' },
                },
                {
                    key: 'fecha_vigencia', label: 'Vigencia', class: 'text-center', thStyle: { width: '16%' },
                },
            ],
        };
    },
}
</script>
