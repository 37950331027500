<template>
    <div class="row mb-2">
        <div class="col-sm-12">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <label for="" class="text-right">Fecha Sincronizacion  {{ fechaSincronizacion }} </label>
                    <label for="" class="text-right pl-3"> {{ modo }} </label>
                </div>
                <div class="row align-items-center">
                    <div class="col-auto">
                        <label for="inputSearch" class="col-form-label">Criterio</label>
                    </div>
                    <div class="col-auto">
                        <input type="text" id="inputSearch" v-model="search" class="form-control" aria-describedby="passwordHelpInline">
                    </div>
                    <div class="col-auto">
                        <button type="button" class="btn th-custom-color" @click="setCriterio()">
                            <i class="glyphicon glyphicon-search text-primary"></i> Buscar
                        </button>
                    </div>
                </div>
                <div class="col-sm-12 py-3 px-2">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="row mb-2">
                                <div class="col-sm-12 table-responsive">
                                    <table class="table table-striped table-bordered table-hover" width="100%">
                                        <thead>
                                            <tr>
                                                <th width="5%">N°</th>
                                                <th width="12%"> Codigo </th>
                                                <th width="23%"> Descripcion </th>
                                                <th width="10%"> Tipo de Actividad </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(leyenda, index) in filtradoData" :key="index">
                                                <td> {{ leyenda.numero }} </td>
                                                <td> {{ leyenda.cod_caeb }} </td>
                                                <td> {{ leyenda.descripcion }} </td>
                                                <td> {{ leyenda.tipo_actividad }} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-auto">&nbsp;</div>
                                    <div class="col-auto">Ver</div>
                                    <div class="col-auto">
                                        <select class="custom-select" @change="onChange($event)">
                                            <option 
                                                v-for="(selec, index2) in rangeToSelect" 
                                                :key="index2"
                                                :value="selec"
                                            >
                                                {{selec}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-auto">
                                        Mostrando {{ filtradoData.length }} de 
                                        {{ total }}
                                    </div>
                                    <div class="col-auto">
                                        <button type="button" class="btn mr-2 btn-secondary" @click="(page = page - 1)">
                                            <i class="glyphicon glyphicon-search text-primary"></i> Anterior
                                        </button>
                                        <button type="button" class="btn mr-2 btn-secondary" @click="(page = page + 1)">
                                            <i class="glyphicon glyphicon-search text-primary"></i> Siguiente
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { get, sync } from 'vuex-pathify';

export default {
    name: "TabActividadSector",
    components: {},
    data() {
        return {
            search: ''
        };
    },
    methods: {
        onChange(event){
            this.range = event.target.value
        },
        setCriterio(){
            this.page = 0
            this.query = this.search
        }
    },
    computed: {
        ...get('sincronizacion/listarActividadSector', [
            'listaData',
            'fechaSincronizacion',
            'total',
            'rangeToSelect',
            'modo'
        ]),
        ...sync('sincronizacion/listarActividadSector', [
            'listaDataTabla',
            'page',
            'range',
            'pages',
            'query',
        ]),
        filtradoData(){
            let result = []
            
            result = this.listaData.filter((data) => {
                return data.numero == this.query ||  data.cod_caeb.toString().includes(this.query) ||  data.descripcion.toString().includes(this.query)
            })
            const start = this.page * this.range
            const limit = (((this.page + 1) * this.range) > this.total ? this.total : ((this.page + 1) * this.range))
            result = result.slice(start, limit)
            return result
        }
    },
    watch: {
        page(value, oldValue){
            this.page = (value < 0 ? oldValue : ((value) * this.range > this.total) ? oldValue : value)
        },
        range(value){
            this.range = value
            this.page = 0
        },
    },
}
</script>
<style scoped>
.btn-orange{
        color: white;
        background-color: orange;
    }
.btn-lighter {
        color: white;
        background-color: #ff8634;
    }
</style>