<template>
    <div>
        <b-modal
        id="modalEventoContigencia"
        title="Datos de Evento/Contigencia" size="lg" hide-footer>
            <div class="modal-body">
                <div class="row px-3">
                    <div class="col-6">
                        <div class="row">
                            <h4 class="h5 font-weight-bold">DATOS DE EVENTO</h4>
                        </div>
                    
                        <div class="form-group row" >
                            <div class="col-4 font-weight-bold p-0">
                                Nro.:
                            </div>
                            <div class="col-8" >
                                {{ detalles.numero }}
                            </div>
                        </div>
                        <div class="form-group row" >
                            <div class="col-4 font-weight-bold p-0">
                                Sucursal:
                            </div>
                            <div class="col-8" >
                                {{ detalles.sucursal }}
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-4 font-weight-bold p-0">
                                Punto de Venta:
                            </div>
                            <div class="col-8">
                                {{ detalles.puntoventa}}
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-4 font-weight-bold p-0">
                                Codigo Recepcion:
                            </div>
                            <div class="col-8">
                                {{ detalles.codigo_recepcion }}
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-4 font-weight-bold p-0">
                                Motivo:
                            </div>
                            <div class="col-8 long-text">
                                {{ detalles.descripcion }}
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-4 font-weight-bold p-0">
                                Cufd Evento:
                            </div>
                            <div class="col-8 long-text">
                                {{ detalles.cufd }}
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-4 font-weight-bold p-0">
                                Fecha/Hora Inicio:
                            </div>
                            <div class="col-8">
                                {{ detalles.hora_inicio }}
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-4 font-weight-bold p-0">
                                Fecha/Hora Fin:
                            </div>
                            <div class="col-8">
                                {{ detalles.hora_fin }}
                            </div>
                        </div>
                            
                        
                    </div>
                    <div class="col-6 pl-4">
                        <div class="row p-0">
                            <h4 class="h5 font-weight-bold">DATOS DE PAQUETE</h4>
                        </div>
                        <div class="form-group row" >
                            <div class="col-4 font-weight-bold p-0">
                                Estado:
                            </div>
                            <div class="col-8 font-weight-bold" :class="detalles.paquete_descripcion == 'PENDIENTE' ? 'text-success' : 'text-danger'">
                                {{ detalles.paquete_descripcion }}
                            </div>
                        </div>
                        <div class="form-group row" >
                            <div class="col-4 font-weight-bold p-0">
                                Codigo:
                            </div>
                            <div class="col-8" >
                                {{ detalles.paquete_recepcion }}
                            </div>
                        </div>
                        <div class="row px-0 pt-5">
                            <h4 class="h5 font-weight-bold">DATOS DE VALIDACIÓN</h4>
                        </div>
                        <div class="form-group row" >
                            <div class="col-4 font-weight-bold p-0">
                                Estado:
                            </div>
                            <div class="col-8 font-weight-bold" :class="detalles.validacion_descripcion=='OBSERVADA' ? 'text-danger' : 'text-success'">
                                {{ detalles.validacion_descripcion }}
                            </div>
                        </div>
                        <div class="form-group row" >
                            <div class="col-4 font-weight-bold p-0">
                                Codigo:
                            </div>
                            <div class="col-8" >
                                {{ detalles.validacion_recepcion }}
                            </div>
                        </div>
                    </div>                       
                    
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button class="btn btn-secondary" @click="cerrarModal">Cancelar</button>
                    <button v-if="detalles.estado == 'Abierto'" class="btn th-custom-color" style="margin-left:11px;" @click="[setCloseEvent(detalles.id), cerrarModal()]">Cerrar Evento</button>
                    <button v-else-if="detalles.validacion_descripcion == 'OBSERVADA'" class="btn th-custom-color" style="margin-left:11px;" @click="[setClosePackage(detalles.id), cerrarModal()]">Enviar Paquete</button>
                    <button v-else class="btn th-custom-color" style="margin-left:11px;" @click="[setValidate(detalles.id), cerrarModal()]">Validar Evento</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import moment from 'moment';
import { call } from 'vuex-pathify';
import 'vue2-datepicker/locale/es';
import 'vue2-datepicker/index.css';

export default {
    name: 'TheModalNuevoEventoContigencia',
    props: {
        detalles: {
            type: Object
        },
    },
    data() {
        return {
            fecha: new Date(moment())
        };
    },
    methods: {
        cerrarModal() {
            this.$bvModal.hide('modalEventoContigencia');
        },
        ...call('sincronizacion/evento', [
            'setCloseEvent',
            'setClosePackage',
            'setValidate'
        ]),
    },
    components: {
        // DatePicker,
    },
}
</script>
<style scoped>
.long-text {
    word-break:break-all !important;
}
</style>