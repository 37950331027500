<template>
    <div>
        <b-modal id="modalNewSucursal" title="Modificar Sucursal" size="lg" hide-footer>
                <form autocomplete="off">
                    <div class="modal-body">
                        <div class="row form-group">
                            <label for="nombre_comercial" class="col-sm-3 col-form-label text-right">Nombre comercial: <span
                                class="text-danger">*</span></label>
                            <div class="col-sm-7">
                                <input type="text" class="form-control" placeholder="Ingrese el nombre comercial"
                                    v-model="sucursal.nombre_comercial"
                                    id="nombre_comercial">
                            </div>
                        </div>
                        <div class="row form-group">
                            <label for="numero_sucursal" class="col-sm-3 col-form-label text-right">N° sucursal: <span
                                class="text-danger">*</span></label>
                            <div class="col-sm-2">
                                <input type="text" class="form-control" placeholder=""
                                    id="numero_sucursal"
                                    v-model="sucursal.numero_sucursal">
                            </div>
                            <label for="telefono" class="col-sm-2 col-form-label text-right">Teléfono:</label>
                            <div class="col-sm-5">
                                <input type="text" class="form-control" placeholder="Ingrese el Telefono"
                                    v-model="sucursal.telefono"
                                    id="telefono">
                            </div>
                        </div>
                        <div class="row form-group">
                            <label for="direccion" class="col-sm-3 col-form-label text-right">Dirección:</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control" placeholder="Ingrese la Direccion"
                                    v-model="sucursal.direccion"
                                    id="direccion">
                            </div>
                        </div>
                        <div class="row form-group">
                            <label for="ciudad" class="col-sm-3 col-form-label text-right">Ciudad:</label>
                            <div class="col-sm-9">
                                <multiselect
                                    id="ciudad"
                                    track-by="nombre"
                                    label="nombre"
                                    placeholder="Seleccione una Ciudad"
                                    v-model="selectedCity"
                                    :show-labels="false"
                                    :options="ciudades"
                                    :searchable="true"
                                    >
                                </multiselect>  
                            </div>
                        </div>                        
                    </div>
                    <hr class="my-4">
                    <div class="w-100">
                        <button class="btn th-custom-color float-right"  type="button" @click="storeSucursal">Guardar</button>
                        <button type="button"
                            class="btn btn-secondary float-right mr-2" @click="cerrarModal">Cancelar
                        </button>
                    </div>
                </form>
        </b-modal>
    </div>
</template>

<script>
import { sync, call } from 'vuex-pathify';
import Multiselect from 'vue-multiselect';

export default {
    name: "theModalNewSucursal",
    components: {
        Multiselect,
    }, 
    data() {
        return {            
            sucursalData: {},
        };
    },       
    computed: {
        ...sync('sincronizacion/modalCreateEditSucursal', [
            'sucursal',
            'modalCreateUpdateSucursal',
            'ciudades',
            'selectedCity'
        ]),
        ...sync('sincronizacion/sucursal/sucursal', [
            "tablaSucursals"
        ]),
    },
    methods : {
        ...call('sincronizacion/modalCreateEditSucursal', ['storeUpdateSucursal']),
        handleCiudadSelected(value){
            this.sucursal.ciudad_nombre = value.nombre
            this.sucursal.ciudad_id = value.id
        },
        async storeSucursal() {
            this.isLoading = true;
            this.cerrarModal();
            await this.storeUpdateSucursal();
            this.isLoading = false;
        },
        cerrarModal() {
            this.$bvModal.hide('modalNewSucursal');
        },
    },
}
</script>