<template>
    <div>
        <b-modal
        id="modalEventoContigenciaConsulta"
        title="Consulta de Evento/Contigencia" size="lg" hide-footer>
            <div class="modal-body">
                <div class="row px-3">
                    <div class="col-7">
                        <div class="form-group row" >
                            <div class="col-3 font-weight-bold">
                                <label for="" class="col-form-label">Sucursal:</label>
                            </div>
                            <div class="col-9" >
                                <multiselect
                                    id="sucursal"
                                    track-by="nombre_comercial"
                                    label="nombre_comercial"
                                    placeholder="Seleccione una Sucursal"
                                    v-model="siat_sucursal_id"
                                    :show-labels="false"
                                    :options="sucursales"
                                    :searchable="true"
                                    >
                                </multiselect>
                            </div>
                        </div>
                        <div class="form-group row" >
                            <div class="col-3 font-weight-bold">
                                <label for="" class="col-form-label">P. de Venta:</label>
                            </div>
                            <div class="col-9" >
                                <multiselect
                                    id="punto_venta"
                                    track-by="nombre_punto_venta"
                                    label="nombre_punto_venta"
                                    placeholder="Seleccione un Punto de Venta"
                                    v-model="siat_puntoventa_id"
                                    :show-labels="false"
                                    :options="punto_ventas"
                                    :searchable="true"
                                    >
                                </multiselect>                                
                            </div>
                        </div>   
                        
                    </div>
                    <div class="col-5">                        
                        <div class="form-group row" >
                            <div class="col-2"></div>
                            <div class="col-2 font-weight-bold">
                                <label for="" class="col-form-label">Fecha:</label>
                            </div>
                            <div class="col-8 font-weight-bold">
                                <date-picker
                                    style="width:120px;"
                                    v-model="fecha"
                                    lang="es"
                                    format="DD/MM/YYYY"
                                    type="date"
                                    value-type="date"
                                ></date-picker>
                            </div>
                        </div>                        
                    </div>                       
                    
                </div>
            </div>
            <div class="">
                <b-table
                    class="table table-striped table-bordered table-hover"
                    :fields="fields_consulta"
                    :busy="loading"
                    :items="listaConsultas"
                >
                </b-table>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button class="btn btn-secondary" @click="cerrarModal">Cerrar</button>
                    <button class="btn th-custom-color ml-2" @click="getEventConsult">Consultar Evento</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { call, sync } from 'vuex-pathify';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/es';
import 'vue2-datepicker/index.css';
import Multiselect from 'vue-multiselect';

export default {
    name: 'TheModalNuevoEventoContigencia',
    props: {
    },
    data() {
        return {
        };
    },
    computed: {
        ...sync('sincronizacion/evento', [            
            'loading',           
            'sucursales',
            'punto_ventas',
            'siat_puntoventa_id',
            'siat_sucursal_id',
            'fecha',
            'listaConsultas',
            'fields_consulta',
        ]),
    },
    methods: {
        cerrarModal() {
            this.$bvModal.hide('modalEventoContigenciaConsulta');
        },
        ...call('sincronizacion/evento', [
            'getEventConsult',
        ]),
    },
    components: {
        DatePicker,
        Multiselect,
    },
}
</script>
<style scoped>
.long-text {
    word-break:break-all !important;
}
</style>