<template>
    <div>
        <div class="row">
                <div class="col-sm-5">
                    <div class="breadcrumb">
                        <h1>Sincronizacion SIAT <span class="h5">{{ subTitle ? '/ ' + subTitle : '' }}</span></h1>
                    </div>
                </div>
        </div>
        <div class="row mb-4">
            <div class="col-sm-12 mb-4">
                <div class="card text-left">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-sm-12 text-right">
                                <button class="btn mr-2"
                                :class="componentShow === 'theViewSincronizar'
                                ? 'th-custom-color' : 'btn-secondary'"
                                @click="componentShow = 'theViewSincronizar', subTitle = 'Parametros Siat'">
                                    Parametros Siat
                                </button>
                                <button class="btn mr-2"
                                :class="componentShow === 'theViewPuntoVenta'
                                ? 'th-custom-color' : 'btn-secondary'"
                                @click="componentShow = 'theViewPuntoVenta', subTitle = 'Punto de Ventas'">
                                    Punto de venta
                                </button>
                                <button class="btn mr-2"
                                :class="componentShow === 'theViewSucrusal'
                                ? 'th-custom-color' : 'btn-secondary'"
                                @click="componentShow = 'theViewSucrusal', subTitle = 'Sucursales'">
                                    Sucursal
                                </button>
                                <button class="btn mr-2"
                                :class="componentShow === 'theViewEvento'
                                ? 'th-custom-color' : 'btn-secondary'"
                                @click="componentShow = 'theViewEvento', subTitle = 'Eventos'">
                                    Eventos
                                </button>
                                <button class="btn mr-2"
                                :class="componentShow === 'theViewCuis'
                                ? 'th-custom-color' : 'btn-secondary'"
                                @click="componentShow = 'theViewCuis', subTitle = 'Cuis'">
                                    CUIS
                                </button>
                                <button class="btn mr-2"
                                :class="componentShow === 'theViewCufds'
                                ? 'th-custom-color' : 'btn-secondary'"
                                @click="componentShow = 'theViewCufds', subTitle = 'Cufd'">
                                    CUFDS
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <TheViewSincronizar
                        v-if="componentShow === 'theViewSincronizar'"
                        />
                        <TheViewPuntoVenta
                        v-if="componentShow === 'theViewPuntoVenta'"/>
                        <TheViewCufds
                        v-if="componentShow === 'theViewCufds'"/>
                        <TheViewCuis
                        v-if="componentShow === 'theViewCuis'"/>
                        <TheViewEventoContigencia
                        v-if="componentShow === 'theViewEvento'"
                        />
                        <TheViewSucrusal
                        v-if="componentShow === 'theViewSucrusal'"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TheViewSincronizar from '../components/TheViewSincronizar.vue';
import TheViewPuntoVenta from '../components/TheViewPuntoVenta.vue';
import TheViewCufds from '../components/TheViewCufds.vue';
import TheViewEventoContigencia from '../components/TheViewEventoContingencia.vue';
import TheViewSucrusal from '../components/TheViewSucursal.vue';
import TheViewCuis from '../components/TheViewCuis.vue';

export default {
    name: "theViewSincronizacion",
    components: {
        TheViewSincronizar,
        TheViewPuntoVenta,
        TheViewCufds,
        TheViewEventoContigencia,
        TheViewSucrusal,
        TheViewCuis,
    },
    data() {
        return {
            componentShow: 'theViewSincronizar',
            subTitle: null,
        };
    },
    methods: {
    },
}
</script>

<style scoped>
</style>