<template>
    <div>
        <div class="row mb-3">
            <div class="col-sm-12 py-3 px-2">
                <div class="row">
                    <div class="col-sm-6">
                        <div class="row mb-2">
                            <div class="col-sm-7">
                                <label for="" 
                                class="col-sm-4 col-form-label text-right"
                                style="padding-top:8px; white-space: nowrap; color:red;">
                                    Estos datos tienen que ser igual al sistema ERP.
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 text-right pr-5">
                        <button class="btn th-custom-color" @click="showModalNewSucursal()">Nuevo</button>                        
                    </div>
                </div>
            </div>
        </div>        
        <div class="row mb-2">
            <div class="col-sm-12">
                <div class="row mb-2">
                    <div class="col-sm-12 table-responsive">
                        <b-table
                            class="table table-striped table-bordered table-hover"
                            :fields="fields"
                            :busy="loading"
                            :items="tablaSucursals"
                        >
                        <template #cell(estado)="row">
                            <button v-if="row.item.estado === 1" class="btn th-custom-color">Activo</button>
                            <button v-else class="btn btn-danger">Anulado</button>
                        </template>
                        <template #cell(acciones)="data">
                            <i class="nav-icon i-Pen-5 font-weight-bold text-success"
                                style="cursor: pointer" title="Editar"
                                @click="[sucursal=data.item, showModalModalSucursal()]"></i>
                            <i class="nav-icon i-Close-Window font-weight-bold text-danger pointer pl-3"
                                style="cursor: pointer" title="Eliminar" @click="destroySucursal(data.item.id)"></i>
                        </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>
        <theModalNewSucursal />
    </div>
</template>

<script>
import {  sync, call } from 'vuex-pathify';
import theModalNewSucursal from '../components/theModalNewSucursal.vue';

export default {
    name: 'sucursal',
    data() {
        return {
            fields: [
                {
                    key: 'numero', label: 'N°', class: 'text-center', thStyle: { width: '3%' }, sortable: true
                },
                {
                    key: 'numero_sucursal', label: 'N° Suc.', class: 'text-center', thStyle: { width: '3%' }, sortable: true
                },
                {
                    key: 'nombre_comercial', label: 'Nombre Comercial', class: 'text-center', thStyle: { width: '20%' }, sortable: true
                },
                {
                    key: 'direccion', label: 'Direccion', class: 'text-center', thStyle: { width: '15%' }, sortable: true
                },
                {
                    key: 'telefono', label: 'Telefono', class: 'text-center', thStyle: { width: '10%' }, sortable: true
                },
                {
                    key: 'ciudad_nombre', label: 'Ciudad', class: 'text-center', thStyle: { width: '15%' }, sortable: true
                },
                {
                    key: 'estado', label: 'Estado', class: 'text-center', thStyle: { width: '10%' }
                },
                {
                    key: 'acciones', label: 'Acciones', class: 'text-center', thStyle: { width: '5%' }
                }
            ],
            showModal:false,
        };
    },
    created(){
        this.getSucursales()
    },
    computed: {
        ...sync('sincronizacion/sucursal/sucursal', [
            "tablaSucursals"
        ]),
        ...sync('sincronizacion/modalCreateEditSucursal', [
            'sucursal',
            'modalCreateUpdateSucursal',
            'ciudades',
            'selectedCity',
        ]),
        loading: sync('sincronizacion/sucursal/sucursal/loading')
    },
    components: {
        theModalNewSucursal,
    },
    methods: {
        ...call('sincronizacion/modalCreateEditSucursal', ['getParams']),
        ...call('sincronizacion/sucursal/sucursal', ['getSucursales', 'destroySucursal']),
        async showModalModalSucursal() {
            await this.getParams()
            this.selectedCity = this.ciudades.filter(e => e.id == this.sucursal.ciudad_id)[0];
            this.$bvModal.show('modalNewSucursal');
        },
        async showModalNewSucursal() {
            await this.getParams()
            this.selectedCity = {}
            this.sucursal = {}
            this.$bvModal.show('modalNewSucursal');
        }
    }
}
</script>